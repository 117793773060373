/* homepage swiper */
.swiper {
    width: 100%;
    .slide-wrapper {
        position: relative;
        img {
            width: 100%;
			max-width: unset;
			@media (max-width: 991px) {
				width: auto;
			}
        }
        .slide-text {
            box-sizing: border-box;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
			@media (max-width: 991px) {
				padding: 0 3.125rem;
			}
        }
    }

    .img-hover-zoom {
        overflow: hidden;
        img {
            transition: transform .5s ease;
        }

        &:hover {
            img {
                transform: scale(1.5);
            }
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        color: $base-color-light;
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
        font-size: 1.875rem;
    }
    .swiper-button-prev {
        left: 15%;
        @media (max-width: 1750px) {
            left: 6%;
        }
		@media (max-width: 1330px) {
            left: 1%;
        }
    }
    .swiper-button-next {
        right: 15%;
        @media (max-width: 1750px) {
            right: 6%;
        }
		@media (max-width: 1330px) {
            right: 1%;
        }
    }
}

/* product image gallery swiper */

.product-gallery-swiper {
    height: 100%;
    .slide-wrapper {
        background: $secondary-color;
        //padding: 6.875rem 1.8125rem;
        padding: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        //box-sizing: border-box;
		@media (max-width: 1700px) {
			padding: 0;
		}
		@media (max-width: 991px) {
			//padding: 20px 10px;
		}
		img {
			padding: 0.125rem;
			@media (max-width: 1440px) {
				//padding: 0;
			}
			@media (max-width: 991px) {
				width: 100%;
				//padding: 1.25rem;
			}
			@media (max-width: 576px) {
				//padding: 1rem;
			}
		}
    }

    .img-hover-zoom {
        padding: 0 !important;
        overflow: hidden;
        img {
            transition: transform .5s ease;
        }

        &:hover {
            img {
                transform: scale(1.5);
            }
        }
    }
}
