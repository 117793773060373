// -----------------------------------------------------------------------------
// This file contains all button component styles
// -----------------------------------------------------------------------------
button {
	all: initial
}

.button-load {

    position: relative;

    .loader-icon {
        color: inherit;
        font-size: 1.5rem;
        position: absolute;
        visibility: hidden;
        top: calc(50% - 0.75rem);
        left: calc(50% - 0.75rem);;
        //-ms-transform: translateY(- (calc(50% - 0.75rem)));
        //transform: translateY(- (calc(50% - 0.75rem)));
        //-ms-transform: translateX(- (calc(50% - 0.75rem)));
        //transform: translateX(- (calc(50% - 0.75rem)));

        transition: visibility .3s;
    }
        .loader {
            border: 16px solid inherit;
            border-radius: 50%;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
}

.btn {
	display: inline-block;
	text-decoration: none;
	font-family: 'Inter', sans-serif;
	background: $base-color-light;
	color: $base-color--dark;
	padding: 1.125rem 2rem;
	max-width: fit-content;
	font-weight: 700;
	border: none;
	cursor: pointer;
	white-space: nowrap;
    transition: .3s all;

    &.like-input {
        padding: .845rem 2rem;
    }

    &.btn-block {
        display: block;
        width: calc(100% - 4rem);
        max-width: 100%;
    }
}

a {
    &.btn {
        font-size: inherit;
        line-height: inherit;

        &.btn-block {
            width: 100%;
        }
    }
}

.btn-dark {
	background: $base-color--dark;
	color: $base-color-light;
    border: 1px solid $base-color-dark;

    &:hover {
        background: $base-color-light;
        color: $base-color--dark;
    }
}

.btn-danger {
    color: $danger-color;
    border: 1px solid $danger-color;

    &:hover {
        background: $danger-color;
        color: $base-color-light;
    }
}


.btn-width-border {
	border: 1px solid $base-color-dark;

    &:hover {
        background: $base-color--dark;
        color: $base-color-light;
    }
}

.btn-with-border-light {
    border: 1px solid $base-color;

    &:hover {
        background: $base-color;
        color: $base-color-light;
    }
}

.btn-gray {
    border: 1px solid $text-color--gray;
    background: $text-color--gray;
    color: $base-color-light;

    &:hover {
        background: transparent;
        color: $base-color-dark;
    }
}
