.ordersContainer {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 150px;
    margin-top: 50px;

    h4 {
        margin-top: 70px;
        color: #0f0f0f;
        text-align: center;
    }

    .titles {
        width: 100%;
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 40px;

        .fakturacne_udaje {
            display: inline-block;
            text-decoration: none;
            font-family: "Inter", sans-serif;
            background: #0f0f0f;
            color: #ffffff;
            padding: 1.125rem 2rem;
            max-width: -moz-fit-content;
            max-width: fit-content;
            font-weight: 700;
            border: none;
            cursor: pointer;
            white-space: nowrap;
            transition: 0.3s all;
        }
    }
}

.orders {
    border: 1px solid #e0e0e0;
    padding: 20px;
    background-color: #e8e7e7;
    padding-top: 30px;
}

.w-5p {
    width: 5%;
}
.w-10p {
    width: 10%;
}
.w-15p {
    width: 15%;
}
.w-20p {
    width: 20%;
}
.w-25p {
    width: 25%;
}
.w-30p {
    width: 30%;
}

h2.orderTitle {
    color: black;
    font-size: 2rem;
    line-height: normal;
}

.orders .order {
    margin-bottom: 20px;
    justify-content: space-between;

    &:first-child {
        //border-bottom: 1px solid #e0e0e0;
    }
}

.orders .order .ref {
    color: rgb(80, 80, 80);
    text-align: center;
}

.orders .order .status {
    text-align: center;
}

.orders .order .status span {
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
}

.orders .order .status .completed {
    background-color: #d1e7dd;
    color: #0a3622;
}

.orders .order .status .completing {
    background-color: #e9d2b0;
    color: #72620f;
}

.orders .order .status .not_completed {
    background-color: #ebc2c2;
    color: #a61212;
}

.orders .order .date {
    color: #a4a4a4;
    text-align: center;
}

.orders .order .price {
    text-align: center;
    color: #a1c900;
}

.orders .order .items {
    display: flex;
    align-items: center;
    justify-content: center;
}
.orders .order .file {
    display: flex;
    align-items: center;
    justify-content: center;
}
.orders .order .cart-product-img-wrap {
    border-radius: 5px;
    width: 2rem;
    height: 2rem;
    background: white;
    padding: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
}


.table-title {
    color: $base-color-dark;
    font-weight: bold;
    font-size: 1rem;
}
