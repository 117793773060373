#cart-steps {
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    margin-bottom: 4rem;
    @media (max-width: 767px) {
        @include justify-content(center);
        @include align-items(flex-start);
        max-width: 40rem;
        margin-left: auto;
        margin-right: auto;
    }
    .cart-steps-item {
        text-align: center;
        margin: 0 1rem;
        &.active {
            .cart-steps-item-number {
                background: $tertiary-color;
                color: $base-color-light;
            }
            img {
                filter: invert(1);
            }
        }
    }
    .cart-steps-item-number {
        width: 3.75rem;
        height: 3.75rem;
        line-height: 3.75rem;
        background: $base-color-light;
        color: $tertiary-color;
        font-size: 1.1rem;
        font-weight: 900;
        border-radius: 1.1rem;
        box-shadow: 0 0 16px rgba(0,0,0,.22);
        margin: auto;
        .a {
            display: initial;
        }
        &.disabled {
            background: #D9D9D9;
        }
    }
    img {
        width: 2rem;
        height: 2rem;
        display: initial;
    }
}
