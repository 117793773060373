////// -----------------------------------------------------------------------------
////// This file contains site header styles
////// -----------------------------------------------------------------------------

//#site-header {
//    position: sticky;
//    left: 0;
//    top: 0;
//    width: 100%;
//    background: $base-color--light;
//    color: $text-color;
//    z-index: 12;

//    will-change: transform;

//    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);

//    transition: background-color 0.3s, transform 0.3s, padding 0.3s;

//    &.headroom {
//        &--unpinned {
//            transform: translateY(-100%);
//        }
//    }

//    &.static {
//        position: relative;
//    }
//}

//.header-top {
//    display: flex;
//    align-items: center;
//	justify-content: space-between;
//    width: 100%;

//    padding: rem(16) 0;

//    .logo {
//        &__img {
//           max-width: 100%;
//        }
//    }
//}

//.header-bottom {
//    border-top: 1px solid $border-color;
//}

//.header-search {
//    //margin-left: rem(57);
//    flex: 1;
//   	max-width: rem(595);

//    @media (max-width: map-get($breakpoints, lg) - 1px) {
//        max-width: 100%;
//        margin: 0 rem(20);
//    }

//    @media (max-width: map-get($breakpoints, sm) - 1px) {
//        position: absolute;
//        top: 0;
//        left: 1rem;
//        width: calc(100% - 11rem);
//        margin: 0;
//        height: 98%;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        z-index: 2;
//        background: $base-color--light;
//        padding: 0;

//        transform: translateY(-100%);
//        transition: transform 0.3s;

//        .form__field {
//            padding: 0;
//        }

//        &.open {
//            transform: translateY(0);
//        }
//    }
//}

////.header-links {
////    margin-right: rem(10);

////    &--static {
////        margin-left: auto;
////    }

////    &__item {
////        display: inline-flex;
////        align-items: center;
////        margin-left: rem(35);
////    }

////    &__icon {
////        margin-right: rem(5);
////    }

////    &__title {
////        text-decoration: underline;
////        font-size: rem(15);
////    }

////    &:not(&--static) {
////        @media (max-width: map-get($breakpoints, lg) - 1px) {
////            display: none;
////        }
////    }
////}

//.header-buttons {
//    $header-buttons: &;

//    //margin-left: auto;

//    display: flex;
//    align-items: center;

//    &__item {
//        display: block;
//        flex-shrink: 0;

//        &:hover {
//            #{$header-buttons}__icon {
//                filter: invert(66%) sepia(88%) saturate(317%) hue-rotate(104deg)
//                    brightness(87%) contrast(101%);
//            }
//        }

//        &--burger {
//            @media (min-width: map-get($breakpoints, lg)) {
//                &.btn--header {
//                    display: none;
//                }
//            }
//        }

//        &--search {
//            @media (min-width: map-get($breakpoints, sm)) {
//                &.btn--header {
//                    display: none;
//                }
//            }
//        }
//    }
//    &__icon {
//        transition: filter 0.3s;
//    }
//}

//.header-menu {
//    display: flex;
//    justify-content: flex-start;
//    align-items: center;

//    @media (max-width: map-get($breakpoints, lg) - 1px) {
//        position: fixed;
//        top: 0;
//        right: 0;

//        display: block;

//        height: 100vh;

//        max-width: 100%;
//        background-color: $base-color;

//        transform: translateX(101%);
//        transition: transform 0.3s ease-in-out;

//        z-index: 13;

//        width: 20rem;

//        &--is-open {
//            transform: translateX(0);
//        }
//    }

//    &__close {
//        // position: absolute;
//        // top: $grid-gutter;
//        // right: $grid-gutter;

//        padding: 1.4rem;
//        text-align: right;
//        background: rgba($base-color, 0.6);
//        backdrop-filter: blur(10px);
//        position: fixed;
//        z-index: 1;
//        width: 100%;
//        top: 0;
//        left: 0;
//    }

//    &__inner {
//        @media (max-width: map-get($breakpoints, lg) - 1px) {
//            height: 100%;
//            width: 100%;
//            overflow-y: scroll;

//            display: flex;
//            flex-direction: column;
//        }
//    }
//}

//.header-menu-list {
//    $header-menu-list: &;

//    list-style: none;
//    padding: 6.3rem 1rem 3rem 1rem;
//    margin: auto 0;

//    display: flex;
//    flex-direction: column;
//    align-items: flex-start;

//    font-weight: 400;
//    font-size: rem(16);
//    letter-spacing: 0.02em;

//    &__item {
//        position: relative;

//        width: 100%;
//        margin: 0;

//        border-radius: $radius;
//        transition: background-color 0.3s;

//        backface-visibility: hidden;

//        &--active {
//            color: $primary-color;
//        }

//        @media (hover: hover) {
//            &:hover {
//                color: $primary-color;
//            }
//        }

//        color: $text-color;
//    }

//    &__link {
//        position: relative;
//        display: block;
//        color: inherit;
//        padding: rem(11) rem(20);
//        cursor: pointer;
//        transition: color 0.3s;

//        background: $base-color--dark;

//        &:hover {
//            color: inherit;
//        }
//    }
//    @media (min-width: map-get($breakpoints, lg)) {
//        flex-direction: row;
//        align-items: center;

//        margin: 0 -#{rem(20)};
//        padding: 0;
//        font-size: rem(16);
//        line-height: inherit;

//        &__item {
//            width: auto;

//            &:last-child {
//                #{$header-menu-list}__link {
//                    padding-right: 0;
//                }
//            }
//        }
//        &__link {
//            display: inline-block;
//            padding: rem(10) rem(20);
//            background: transparent;
//        }
//    }
//}

//.header-menu-dropdown {
//    $dropdown: &;

//    @media (min-width: map-get($breakpoints, lg)) {
//        position: absolute;
//        padding-top: $grid-gutter;
//        top: 100%;
//        left: 0;

//        visibility: hidden;
//        opacity: 0;
//        transform: translateY(1rem);
//        transition: all 0.3s;
//        backface-visibility: hidden;
//    }

//    &__list {
//        list-style: none;
//        overflow: hidden;

//        @media (min-width: map-get($breakpoints, lg)) {
//            background-color: $base-color--light;
//            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
//        }
//    }

//    &__item {
//        position: relative;

//        transition: background-color 0.3s;

//        margin: rem(5) 0;
//        color: $text-color;
//        text-align: center;

//        @media (max-width: map-get($breakpoints, lg) - 1px) {
//            text-align: left;
//            border-radius: $radius;
//        }

//        &--active {
//            color: $primary-color;
//        }

//        @media (hover: hover) {
//            &:hover {
//                color: $primary-color;
//            }
//        }

//        @media (min-width: map-get($breakpoints, lg)) {
//            white-space: nowrap;
//            margin: rem(0);
//            text-align: left;
//        }
//    }

//    &__link {
//        position: relative;
//        display: block;
//        color: inherit;
//        padding: rem(10) rem(20);

//        &:hover {
//            color: inherit;
//        }
//    }

//    &__opener {
//        &:focus {
//            outline: none;
//        }

//        .header-menu-list__link {
//            display: block;

//            &::after {
//                content: "";
//                display: inline-block;
//                width: rem(8);
//                height: rem(7);
//                flex-shrink: 0;
//                margin-left: rem(6);
//                transform: translateY(2px);
//                background-image: url("../../img/icons/arrow-down.svg");
//                background-position: center;
//                background-repeat: no-repeat;
//                background-size: contain;
//            }
//        }
//        @media (min-width: map-get($breakpoints, lg)) {
//            .header-menu-list__link {
//                display: inline-flex;
//                align-items: center;
//            }
//            &:hover,
//            &:focus,
//            &:focus-within {
//                #{$dropdown} {
//                    visibility: visible;
//                    opacity: 1;
//                    transform: translateY(0);
//                }
//            }
//        }
//    }
//}

//.header-btn {
//    @media (min-width: map-get($breakpoints, lg)) {
//        display: none;
//    }
//}

header {
    border-bottom: 1px solid #ddd;
	@media (max-width: 991px) {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 30;
		width: 100%;
		background: $base-color-light;
	}
}

nav {
    .nav-buttons {
        .menu-btn {
            display: block;
            padding: 25px;
            background: $base-color-dark;
        }
        img.btn__icon {
            max-width: unset;
        }
    }
	img.logo {
		max-width: unset;
		@media (max-width: 576px) {
			padding: 1.25rem 1.25rem 1.25rem 0;
		}
	}
}

.header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000000, 0.3);
    backdrop-filter: blur(4px);
    transform: translateX(100%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &--active {
        opacity: 1;
        transform: translateX(0);
    }
}

.header-menu {
    position: fixed;
    top: 0;
    right: 0;

    display: block;

    height: 100vh;

    max-width: 100%;
    background-color: $base-color-light;

    transform: translateX(101%);
    transition: transform 0.3s ease-in-out;

    z-index: 30;

    width: 40rem;

    &--is-open {
        transform: translateX(0);
    }
    &__close {
        padding: 1.4rem;
        text-align: right;
        background: $base-color-light;
        backdrop-filter: blur(10px);
        position: fixed;
        z-index: 1;
        width: 100%;
        top: 0;
        left: 0;
        img {
            width: 1.3rem;
        }
    }
}

.header-menu-list {
    list-style: none;
    padding: 6.3rem 1rem 3rem 1rem;
    margin: auto 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    font-weight: 400;
    font-size: rem(16);
    letter-spacing: 0.02em;
    .nav-item a, .nav-item button {
        font-family: "Inter", sans-serif;
        cursor: pointer;
        font-size: 2.375rem;
        line-height: normal;
        padding: 1.5rem 2rem;
        margin: 0.3rem 2rem;
        display: inline-block;
        position: relative;
        color: $base-color-dark;
        &:hover:before {
            opacity: 1;
            transform: scale(1);
        }
        &:hover {
            color: $base-color-light;
            transition: color 0.7s ease;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $base-color-dark;
            transform: scaleX(0);
            opacity: 0;
            z-index: -1;
            transform-origin: left;
            transition: transform 0.4s ease, opacity 0.3s;
        }
    }
    .nav-item {
        width: 100%;

        .separator-wrap {
            display: flex;
            width: 100%;
            padding: 0 4rem;
        }
        .separator {
            width: 100%;
            border-bottom: 1px solid $base-color;
        }
    }
    .nav-item-box {
        padding: 1.1rem 4rem;
        //margin: 0 2rem;
        display: inline-block;
        width: 100%;
    }

    @media (max-width: 768px) {
        .nav-item {

            a,
            button {
                font-size: 1.4rem;
                padding: 0.4rem 1rem;
                margin: 0.2rem 1rem;
            }

            .separator-wrap {
                padding: 1rem 3rem;
            }
        }

        .nav-item-box {
            padding: 1.2rem 2.6rem;
        }
    }
}
