.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0;
}

.list-group-item {
    overflow: hidden;
    position: relative;
    display: block;
    padding: 0;
    width: 100%;
    height: auto;
    color: inherit;
    border-right: 1px solid  rgba($base-color-dark, .125);
    border-left: 1px solid  rgba($base-color-dark, .125);
    border-bottom: 1px solid  rgba($base-color-dark, .125);

    &:first-child {
        border-top: 1px solid  rgba($base-color-dark, .125);
    }

    .button {
        font-family: "Inter", sans-serif;
        padding: 1rem 1.25rem;
        width: -webkit-fill-available;

        &:hover {
            cursor: pointer;
        }

        &.bg-danger {
            background-color: #f5c6cb;
            color: #721c24;
        }
        &.bg-dark {
            color: #1b1e21;
            background-color: #c6c8ca;
        }
    }
}
