.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-open {
    overflow: hidden;

    .modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.modal-dialog{
    height: 100%;
    //max-width: 450px;
    position: relative;
    width: auto;
    background-color: rgba($base-color-dark, .5);
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

//@import "~bootstrap/scss/modal";
.modal-content{
    max-width: 40rem;
    font-family: $text-font;
    color: $base-color-dark;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 !important;
    -webkit-box-shadow: 0px 10px 34px -15px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0,0,0,0.24);
    box-shadow: 0px 10px 34px -15px rgba(0,0,0,0.24);
    background: $base-color-light;
    .modal-header{
        position: relative;
        padding: 0;
        border: none;
    }
    button.close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        margin: 0;
        width: 40px;
        height: 40px;
        z-index: 1;
    }
    .modal-body{
        border: none;
        label{
            text-transform: uppercase;
            font-size: 12px;
            color: rgba(0,0,0,.3);
        }
        .forgot{
            color: rgba(0,0,0,.3);
            text-decoration: underline;
        }
    }
}
