@import '~intl-tel-input/build/css/intlTelInput.css';

.iti__flag {background-image: url("../../img/flags/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {background-image: url("../../img/flags/flags@2x.png");}
}

.phone-wrap {
    .iti {
        width: 100%;
        &__arrow {
            border-top: 5px solid #fff;
            .iti__arrow--up {
                border-top: none;
                border-bottom: 4px solid #fff;
            }
        }
        &__country {
            padding: 10px;
        }
    }

    &.dark {
        .iti {
            &__arrow {
                border-top: 4px solid #555;
                .iti__arrow--up {
                    border-top: none;
                    border-bottom: 4px solid #555;
                }
            }
        }
    }
}

