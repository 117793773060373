

$text-font: "Inter", sans-serif;
$title-font: "Inter", sans-serif;

$font-size: rem(17);
$font-weight: 400;
$line-height: 1.6;

///
$base-color: #b0b0b0;
$base-color-light: #ffffff;
$base-color-dark: #0f0f0f;
$base-color--dark: #0f0f0f;
$secondary-color: #f8f8f8;
$bg-color-light: #FEFEFE;
///

$text-color: #b0b0b0;
$text-color--gray: #9ca3af;
$text-color--light: #fff;
$special-color: #ff3f3f;
$danger-color: #dc3545;

$primary-color: #34d399;
$primary-color--dark: darken($primary-color, 5%);
$tertiary-color: #34373c;

$gradient-1-primary: #635ee2;
$gradient-1-secondary: #0085ff;

$border-color: #e5e7eb;

$box-shadow: 0px 36px 198px rgba(0, 0, 0, 0.07),
    0px 15.0399px 82.7197px rgba(0, 0, 0, 0.0503198),
    0px 8.04107px 44.2259px rgba(0, 0, 0, 0.0417275),
    0px 4.50776px 24.7927px rgba(0, 0, 0, 0.035),
    0px 2.39404px 13.1672px rgba(0, 0, 0, 0.0282725),
    0px 0.996212px 5.47917px rgba(0, 0, 0, 0.0196802);

$box-shadow--medium: 4px 4px 11px rgba(0, 0, 0, 0.2);
$box-shadow--small: 0px 1px 2px rgba(0, 0, 0, 0.05);

$radius: 8px;
$radius--sm: 4px;

$grid-gutter: 1rem;
$main-gutter: 4.813rem;
$header-gutter: 6.5rem;

$letter-spacing: (
    "sm": 0.02em,
    "lg": 0.15em,
);

$breakpoints: (
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1200px,
    // "xxl": 1400px
);

$global-font-sizes: (
    "xs": 80%,
    "sm": 85%,
    "md": 90%,
    "lg": 95%,
    "xl": 100%,
    // "xxl": 100%
);

$gutters: (
    "xs": 0.5rem,
    "sm": 1rem,
    "md": 2rem,
    "lg": 5rem,
    "xl": 8rem,
);

$orders: (
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
);


