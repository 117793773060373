h1 {
    font-size: 3rem;
    line-height: 3.5rem;
    color: $base-color-light;
    &.dark {
        color: $base-color-dark;
    }
    &.breakline {
        max-width: 27rem;
    }
    @media (max-width: 576px) {
        font-size: 2rem;
        line-height: 3rem;
    }
}

h2,
.h2 {
    font-size: 2.625rem;
    line-height: 3.9375rem;
    color: $base-color-light;
    font-weight: 700;
    &.dark {
        color: $base-color-dark;
    }
    &.breakline {
        max-width: 20rem;
        @media (max-width: 1200px) {
            max-width: 100%;
        }
    }
    @media (max-width: 576px) {
        font-size: 2rem;
        line-height: 3rem;
    }
}

h3 {
    font-size: 2.25rem;
    line-height: 3.375rem;
    @media (max-width: 576px) {
        font-size: 1.7rem;
        line-height: 2rem;
    }
    &.breakline {
        max-width: 10rem;
    }
}

h4, .h4 {
    font-size: 1.375rem;
    line-height: 32px;
    @media (max-width: 576px) {
        font-size: 1.1rem;
        line-height: 1.5rem;
    }
    &.light {
        color: $base-color-light;
    }
    &.dark {
        color: $base-color-dark;
    }
}


a, button.like-a {
    font-size: 1.125rem;
    line-height: 1.625rem;
    color: $base-color-dark;
    text-decoration: none;
    @media (max-width: 576px) {
        font-size: .9rem;
        line-height: 1.5rem;
    }
    &.light {
        color: $base-color-light;
        &:hover {
            text-decoration: underline;
        }
    }
    &.lighter {
        font-weight: 300;
        &:hover {
            text-decoration: underline;
        }
    }
    &.grey-link-hover-underline {
        color: $base-color;
        text-decoration: none;
        font-size: inherit;
        &:hover {
            text-decoration: underline;
        }
    }
    &.grey-link {
        color: $base-color;
        text-decoration: underline;
        font-size: inherit;
        &:hover {
            text-decoration: none;
        }
    }
    &.danger-link {
        color: $danger-color;
        text-decoration: underline;
        font-size: inherit;
        &:hover {
            text-decoration: none;
        }
    }
    &.show-more-link {
		white-space: nowrap;
    }

    &.no-style {
        font-size: 1rem;
        line-height: normal;
        color: inherit;
        text-decoration: none;
        @media (max-width: 576px) {
            font-size: 1rem;
            line-height: normal;
        }
    }

    &.base-style {
        font-size: 1rem;
        line-height: normal;
        @media (max-width: 576px) {
            font-size: 1rem;
            line-height: normal;
        }
    }
}
button.like-a {
    -webkit-appearance: none !important;
    background-color: transparent;
    font-family: $text-font !important;

    &:hover {
        cursor: pointer;
    }
}

p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: $base-color-dark;
    font-weight: 400;
    @media (max-width: 576px) {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
    &.light-color {
        color: $base-color-light;
    }
    &.light-grey-color {
        color: $base-color;
    }
    &.light-grey {
        color: darken($base-color, 20%);
    }
    &.bolder {
        font-weight: 700;
    }
    &.smaller {
        font-size: 0.875rem;
        line-height: 1.625rem;
    }
    &.lighter {
        font-size: 18px;
        font-weight: 300;
    }
    &.breakline {
        max-width: 23rem;
    }
}

.about-section {
    p {
        color: inherit !important;
    }
}
.hover-underline {
    &:hover {
        text-decoration: underline;
    }
}

.hover-bold {
    &:hover {
        font-weight: bold;
    }
}

.text-color-gray {
    color: $text-color
}

.text-color-dark {
    color: $base-color--dark
}

.text-color-tertiary {
    color: $tertiary-color
}

.text-color-light {
    color: $text-color--light

}
.text-color-light > * {
    color: $text-color--light
}

.font-weight-bold {
    font-weight: bold;
}



.footer-font {
    margin: 0;
    display: block;
    padding: 0.3rem 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
    text-decoration: none;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}


.font-size-1-3rem {
    font-size: 1.3rem !important;
}

.font-size-1-2rem {
    font-size: 1.2rem !important;
}

.font-size-1-1rem {
    font-size: 1.1rem !important;
}

.font-size-1rem {
    font-size: 1rem !important;
}

.font-size-0-9rem {
    font-size: .9rem !important;
    line-height: 1.4rem !important;
}

.font-size-0-8rem {
    font-size: .8rem !important;
    line-height: 1.3rem !important;
}

.text-editor-wrap {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, span, table, ul, li, ol, div {
        color: inherit;
        align-items: inherit;
    }
}
