.cart {
    position: relative;

    &__icon {
        width: 2rem;
        height: 2rem;
        color: inherit;
    }

    &__icon-wrap {
        display: flex;
        position: relative;
        height: 100%;
        width: 100%;
    }

    &__counter {
        position: absolute;
        top: -0.9rem;
        //right: -0.9rem;
        right: 0.5rem;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $special-color;
        transition: background 0.3s;
        color: $text-color--light;
    }
    &__counter-text {
        line-height: normal;
        font-size: 0.75rem;
        font-weight: 700;
        text-align: center;
        color: $text-color--light;
    }

    &__list-products {
        z-index: -350;
        opacity: 0;
        //display: none;

        position: absolute;
        top: calc(100% + 1.35rem);
        right: 0;
        @include flexbox();
        @include flex-direction(column);
        min-width: 25rem;
        max-width: 100vw;

        transition: all .3s ease-in-out;

        @media (max-width: 576px) {
            top: 4.6rem;
            left: 0;
            width: 100vw;
            position: fixed;
        }
    }

    &__list-products-box {
        position: relative;
        border: 1px solid #ddd;
    }

    &__list-wrap-summary {
        width: 100%;
        border-top: 1px solid #ddd;
        background: #fff;
        padding: 2rem 4rem;
    }

    &__list-products-wrap {
        max-height: 22rem;
        overflow-y: auto;
    }

    &__list-products-item-wrap {
        background: lighten(#f9f9f9, 5%);
        border-bottom: 1px solid #ddd;
        transition: .3s all;

        &:hover {
            background: darken(#f9f9f9, 3%);
        }
    }

    &__list-products-img-wrap {
        width: 30%;
    }

    &__list-products-img {
        width: 100%;
        min-height: 100%;
        height: auto;
        object-fit: cover;
    }

    &__list-products-text-wrap {
        width: 70%;
        padding: 1rem;
    }

    &__list-products-title {
        font-size: 1rem;
        line-height: 1.2rem;
        vertical-align: middle;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    &__list-products-title-price {
        font-size: 1rem;
        line-height: 1.2rem;
        vertical-align: middle;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__list-products-subtitle-price {
        font-size: .7rem;
        line-height: .85rem;
        vertical-align: middle;
    }

    &__list-products-text {
        font-size: 0.8rem;
        line-height: 1rem;
        vertical-align: middle;
    }

    &__list-products-price-wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: .5rem;
    }

    &__list-products-price {
        font-size: 1rem;
        line-height: 1.2rem;
        vertical-align: middle;
        font-weight: bold;
        text-align: right;
    }

    &__list-products-price-unit {
        font-size: .8rem;
        line-height: 1rem;
        vertical-align: bottom;
        font-weight: normal;
        text-align: left;
    }

    &__list-products-title-price-btn {
        text-transform: uppercase;
        margin-top: 1rem;
        width: 100%;
        min-width: 100%;
        text-align: center;
        font-size: .85rem;
        line-height: .85rem;
        padding: 0.8rem 2rem;
    }
}

.designer-zone-icon {
    position: relative;
    width: max-content;

    &__icon {
        width: 2rem;
        height: 2rem;
        color: inherit;
    }
}

.cart-icon {
    position: relative;
    width: max-content;

    &:hover {
        .cart__list-products {
            opacity: 1;
            z-index: 50;
            //@include flexbox();
        }
    }

    &__icon {
        width: 2rem;
        height: 2rem;
        color: inherit;
    }

    &__counter {
        position: absolute;
        top: -0.9rem;
        right: -0.9rem;
        width: 1.2rem;
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $special-color;
        transition: background 0.3s;
        color: $text-color--light;
    }
    &__counter-text {
        line-height: normal;
        font-size: 0.75rem;
        font-weight: 700;
        text-align: center;
        color: $text-color--light;
    }
}
